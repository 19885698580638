import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import serialize from "form-serialize";
import { Card, CardBody, Form, FormGroup, Label, Col, Row } from 'reactstrap';

import CountdownTimer from './CountdownTimer';

import StartImage from '../../assets/starts.png';

import axiosInstance from '../../Utilities/axios';

import {URL_BACKEND} from '../../config.js';

import Question1 from '../../assets/QCM/Question1.jpg';
import Question2 from '../../assets/QCM/Question2.jpg';
import Question3_1 from '../../assets/QCM/Question3(1).jpg';
import Question3_2 from '../../assets/QCM/Question3(2).jpg';
import Question4_1 from '../../assets/QCM/Question4(1).jpg';
import Question4_2 from '../../assets/QCM/Question4(2).jpg';
import Question5 from '../../assets/QCM/Question5.jpg';
import Question6 from '../../assets/QCM/Question6.jpg';
import Question7 from '../../assets/QCM/Question7.jpg';
import Question8 from '../../assets/QCM/Question8.jpg';
import Question9 from '../../assets/QCM/Question9.jpg';
import Question10_1 from '../../assets/QCM/Question10(1).jpg';
import Question10_2 from '../../assets/QCM/Question10(2).jpg';
import Question11 from '../../assets/QCM/Question11.jpg';
import Question12_1 from '../../assets/QCM/Question12(1).jpg';
import Question12_2 from '../../assets/QCM/Question12(2).jpg';
import Question13 from '../../assets/QCM/Question13.jpg';
import Question14_1 from '../../assets/QCM/Question14(1).jpg';
import Question14_2 from '../../assets/QCM/Question14(2).jpg';
import Question15_1 from '../../assets/QCM/Question15(1).jpg';
import Question15_2 from '../../assets/QCM/Question15(2).jpg';
import Question16_1 from '../../assets/QCM/Question16(1).jpg';
import Question16_2 from '../../assets/QCM/Question16(2).jpg';
import Question17 from '../../assets/QCM/Question17.jpg';
import Question18 from '../../assets/QCM/Question18.jpg';
import Question19 from '../../assets/QCM/Question19.jpg';
import Question20_1 from '../../assets/QCM/Question20(1).jpg';
import Question20_2 from '../../assets/QCM/Question20(2).jpg';
import Question21 from '../../assets/QCM/Question21.jpg';
import Question22_1 from '../../assets/QCM/Question22(1).jpg';
import Question22_2 from '../../assets/QCM/Question22(2).jpg';
import Question23 from '../../assets/QCM/Question23.jpg';
import Question24 from '../../assets/QCM/Question24.jpg';
import Question25_1 from '../../assets/QCM/Question25(1).jpg';
import Question25_2 from '../../assets/QCM/Question25(2).jpg';
import Question26 from '../../assets/QCM/Question26.jpg';
import Question27 from '../../assets/QCM/Question27.jpg';
import Question28 from '../../assets/QCM/Question28.jpg';
import Question29 from '../../assets/QCM/Question29.jpg';
import Question30 from '../../assets/QCM/Question30.jpg';
import Question31 from '../../assets/QCM/Question31.jpg';
import Question32 from '../../assets/QCM/Question32.jpg';
import Question33 from '../../assets/QCM/Question33.jpg';
import Question34 from '../../assets/QCM/Question34.jpg';
import Question35_1 from '../../assets/QCM/Question35(1).jpg';
import Question35_2 from '../../assets/QCM/Question35(2).jpg';
import Question36 from '../../assets/QCM/Question36.jpg';
import Question37 from '../../assets/QCM/Question37.jpg';
import Question38 from '../../assets/QCM/Question38.jpg';
import Question39_1 from '../../assets/QCM/Question39(1).jpg';
import Question39_2 from '../../assets/QCM/Question39(2).jpg';
import Question40 from '../../assets/QCM/Question40.jpg';



    

function Survey(props) {


    //const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(-1);
    const [error, seterror] = useState('Error encountered ! Please retry the test if the token given by ACESY has not expired!');

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [loading, setLoading] = useState(false);

    const TIMER_END = props.InfoTimer;
    //const TIMER_END = 1 * 90 * 60 * 1000;
    const NOW_IN_MS = new Date().getTime();

    const dateTimeAfterTest = NOW_IN_MS + TIMER_END;

    const onSubmit = (data) => {
        
        console.log(data);

        saveData(data);

    };

    const saveData = async (data) => {
        setLoading(true)
        axiosInstance.post(URL_BACKEND+"/testIq", data)
            .then(res => {
                setLoading(false)
                setShowScore(true)
                console.log(res);

                if(res.status === 201){
                    setScore(res.data.form.Score);
                }else{
                    seterror(res.data.error)
                }

                
            }).catch(function (error){
                setLoading(false)
                setShowScore(true);
				seterror('Error encountered ! Check your connexion and retry the test if the token given by ACESY has not expired!')
                console.log(error)
            })
    }
    

    useEffect(() => {
        const timeout = setTimeout(() => {
            var form = document.querySelector('#SurveyForm');
    
            var data = serialize(form, { hash: true });
    
            handleSubmit(onSubmit(data));
            
        }, TIMER_END);
    },[]);

    const checkKeyDown = (e) =>{
        if(e.keyCode === 13 || e.which === 13) e.preventDefault();
    }


    return (
        <div>
            {showScore ? (
                <Card className='app'>
                    <CardBody className='score-section'>
                        <img src={StartImage} alt="QI_Image" />
                        <div className='mt-2'>
                            {score === -1 ? (<p className='text-danger'>{error}</p>)
                            :(<p>You scored {score} out of 40</p>)}
                        </div>
                    </CardBody>
                </Card>
            ) : (

                <>
                    <Row className='Center-Content'>
                        <Col sm="12">
                            <Card >
                                <CardBody>

                                    <h2><CountdownTimer targetDate={dateTimeAfterTest} /></h2>
                                    <Form id='SurveyForm' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                                        <h2 className="survey-title"> IQ TEST </h2>
                                        <Label >
                                            First Name:
                                        </Label><br />
                                        <input type="text" {...register("FirstName", { required: true })} /><br />
                                        <Label >
                                            Last Name:
                                        </Label><br />
                                        <input type="text" {...register("LastName", { required: false })}/><br />
                                        <Label>
                                            Email:
                                        </Label><br />
                                        <input type="email" value={props.InfoEmail} {...register("Email", { required: true })} readOnly={true} /><br />
                                        <Label>
                                            Phone Number:
                                        </Label>
                                        <br />
                                        <input type="tel" {...register("PhoneNumber", { required: true })}/><br />
                                        <Label>
                                            Token:
                                        </Label>
                                        <br />
                                        <input type="text" value={props.InfoToken} {...register("Token", { required: true })} readOnly={true} /><br /><br />
                                        <br />
                                        <h3 className='text-danger'>* Enter your information before starting the test</h3>
                                        <br />
                                        <h3 className='text-danger'>* Just fill the answers without putting spaces or other characters</h3>
                                        <br />
                                        <h3 className='text-danger'>* You are allowed to take the test only once. </h3>
                                        <br />
                                        <hr className="solid" />
                                        <FormGroup>
                                            <Label>QUESTION 1</Label><br />
                                            <span>What weight should replace the question mark in order for the scales to balance?</span>
                                            <div> <img className="image-question" src={Question1} alt='Question1' /> </div>
                                            <br />
                                            <div>
                                                <input type="text" {...register("Question1")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 2</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question2} alt='Question2' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question2")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 3</Label><br />
                                            <div> <img className="image-question" src={Question3_1} alt='Question3A' /> </div>
                                            <span>
                                                To which shield below can a dot be added so that it meets the same conditions as in the shield above?
                                            </span>
                                            <div> <img className="image-question" src={Question3_2} alt='Question3B' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question3")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question3")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question3")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question3")} />
                                                    <span> D</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="E" {...register("Question3")} />
                                                    <span> E</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 4</Label><br />
                                            <div> <img className="image-question" src={Question4_1} alt='Question4A' /> </div>
                                            <span>
                                                Which is the missing section?
                                            </span>
                                            <div> <img className="image-question" src={Question4_2} alt='Question4B' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question4")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question4")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question4")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question4")} />
                                                    <span> D</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 5</Label><br />
                                            <span>
                                                Which is the odd one out?
                                            </span>
                                            <div> <img className="image-question" src={Question5} alt='Question5' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question5")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question5")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question5")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question5")} />
                                                    <span> D</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="E" {...register("Question5")} />
                                                    <span> E</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 6</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question6} alt='Question6' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question6")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 7</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question7} alt='Question7' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question7")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 8</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question8} alt='Question8' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question8")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 9</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question9} alt='Question9' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question9")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 10</Label><br />
                                            <div> <img className="image-question" src={Question10_1} alt='Question10A' /> </div>
                                            <span>
                                                What comes next?
                                            </span>
                                            <div> <img className="image-question" src={Question10_2} alt='Question10B' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question10")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question10")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question10")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question10")} />
                                                    <span> D</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="E" {...register("Question10")} />
                                                    <span> E</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 11</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question11} alt='Question11' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question11")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 12</Label><br />
                                            <div> <img className="image-question" src={Question12_1} alt='Question12A' /> </div>
                                            <span>
                                                To which hexagon below can a dot be added so that both dots then meet the same conditions as the two dots in the hexagon above?
                                            </span>
                                            <div> <img className="image-question" src={Question12_2} alt='Question12B' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question12")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question12")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question12")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question12")} />
                                                    <span> D</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="E" {...register("Question12")} />
                                                    <span> E</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 13</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question13} alt='Question13' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question13")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 14</Label><br />
                                            <div> <img className="image-question" src={Question14_1} alt='Question14A' /> </div>
                                            <span>
                                                Which is the missing tile?
                                            </span>
                                            <div> <img className="image-question" src={Question14_2} alt='Question14B' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question14")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question14")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question14")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question14")} />
                                                    <span> D</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="E" {...register("Question14")} />
                                                    <span> E</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="F" {...register("Question14")} />
                                                    <span> F</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="G" {...register("Question14")} />
                                                    <span> G</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="H" {...register("Question14")} />
                                                    <span> H</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 15</Label><br />
                                            <div> <img className="image-question" src={Question15_1} alt='Question15A' /> </div>
                                            <span>
                                                Which is missing?
                                            </span>
                                            <div> <img className="image-question" src={Question15_2} alt='Question15B' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question15")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question15")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question15")} />
                                                    <span> C</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 16</Label><br />
                                            <div> <img className="image-question" src={Question16_1} alt='Question16A' /> </div>
                                            <span>
                                                What comes next?
                                            </span>
                                            <div> <img className="image-question" src={Question16_2} alt='Question16B' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question16")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question16")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question16")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question16")} />
                                                    <span> D</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="E" {...register("Question16")} />
                                                    <span> E</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 17</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question17} alt='Question17' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question17")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 18</Label><br />
                                            <p>
                                                Insert the numbers 1–5 in the circles so that for any particular circle the sum of
                                                the numbers in the circles connected directly to it equals the value corresponding
                                                to the number in that circle as given in the list.
                                            </p>
                                            <div> <img className="image-question" src={Question18} alt='Question18' /> </div>
                                            <br />
                                            <div>
                                                <div>
                                                    <input type="text" placeholder="A" {...register("Question18A")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="B" {...register("Question18B")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="C" {...register("Question18C")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="D" {...register("Question18D")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="E" {...register("Question18E")} />
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 19</Label><br />
                                            <span>
                                                Which is the odd one out?
                                            </span>
                                            <div> <img className="image-question" src={Question19} alt='Question19' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question19")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question19")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question19")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question19")} />
                                                    <span> D</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="E" {...register("Question19")} />
                                                    <span> E</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 20</Label><br />
                                            <div> <img className="image-question" src={Question20_1} alt='Question20A' /> </div>
                                            <span>
                                                Which circle should replace the question mark?
                                            </span>
                                            <div> <img className="image-question" src={Question20_2} alt='Question20B'/> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question20")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question20")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question20")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question20")} />
                                                    <span> D</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="E" {...register("Question20")} />
                                                    <span> E</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 21</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question21} alt='Question21' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question21")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 22</Label><br />
                                            <div> <img className="image-question" src={Question22_1} alt='Question22A' /> </div>
                                            <span>
                                                Which is the missing tile?
                                            </span>
                                            <div> <img className="image-question" src={Question22_2} alt='Question22B' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question22")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question22")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question22")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question22")} />
                                                    <span> D</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="E" {...register("Question22")} />
                                                    <span> E</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="F" {...register("Question22")} />
                                                    <span> F</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 23</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question23} alt='Question23' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question23")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 24</Label><br />
                                            <span>What percentage of the figure is shaded?</span>
                                            <div> <img className="image-question" src={Question24} alt='Question24' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question24")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 25</Label><br />
                                            <div> <img className="image-question" src={Question25_1} alt='Question25A' /> </div>
                                            <span>
                                                Which is the missing section?
                                            </span>
                                            <div> <img className="image-question" src={Question25_2} alt='Question25B' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question25")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question25")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question25")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question25")} />
                                                    <span> D</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 26</Label><br />
                                            <span>
                                                Place the numbers 1–5 in the circles so that:
                                                the sum of the numbers 2 and 4 and all the numbers between them total 15;
                                                the sum of the numbers 3 and 2 and all the numbers between them total 11;
                                                the sum of the numbers 5 and 4 and all the numbers between them total 12;
                                                the sum of the numbers 1 and 3 and all the numbers between them total 9.
                                            </span>
                                            <div> <img className="image-question" src={Question26} alt='Question26' /> </div>
                                            <br />
                                            <div>
                                                <div>
                                                    <input type="text" placeholder="A" {...register("Question26A")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="B" {...register("Question26B")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="C" {...register("Question26C")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="D" {...register("Question26D")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="E" {...register("Question26E")} />
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 27</Label><br />
                                            <span>What is the next number in this sequence?</span>
                                            <div> <img className="image-question" src={Question27} alt='Question27' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question27")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 28</Label><br />
                                            <span>How many different sized circles appear below?</span>
                                            <div> <img className="image-question" src={Question28} alt='Question28' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question28")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 29</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question29} alt='Question29' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question29")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 30</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question30} alt='Question30' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question30")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 31</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question31} alt='Question31' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question31")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 32</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question32} alt='Question32' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question32")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 33</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question33} alt='Question33' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question33")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 34</Label><br />
                                            <span>What is the next number in this sequence?</span>
                                            <div> <img className="image-question" src={Question34} alt='Question34' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question34")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 35</Label><br />
                                            <div> <img className="image-question" src={Question35_1} alt='Question35A' /> </div>
                                            <span>
                                                Which section is missing?
                                            </span>
                                            <div> <img className="image-question" src={Question35_2} alt='Question35B' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question35")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question35")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question35")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question35")} />
                                                    <span> D</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 36</Label><br />
                                            <span>
                                                What numbers should replace the question marks?
                                            </span>
                                            <div> <img className="image-question" src={Question36} alt='Question36' /> </div>
                                            <br />
                                            <div>
                                                <div>
                                                    <input type="text" placeholder="A" {...register("Question36A")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="B" {...register("Question36B")} />
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 37</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question37} alt='Question37' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question37")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 38</Label><br />
                                            <span>
                                                Each number in the pyramid is the sum of the two numbers immediately below
                                                it. Fill in the pyramid with the missing numbers.
                                            </span>
                                            <div> <img className="image-question" src={Question38} alt='Question38' /> </div>
                                            <br />
                                            <div>
                                                <div>
                                                    <input type="text" placeholder="A" {...register("Question38A")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="B" {...register("Question38B")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="C" {...register("Question38C")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="D" {...register("Question38D")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="E" {...register("Question38E")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="F" {...register("Question38F")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="G" {...register("Question38G")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="H" {...register("Question38H")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="I" {...register("Question38I")} />
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="J" {...register("Question38J")} />
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 39</Label><br />
                                            <div> <img className="image-question" src={Question39_1} alt='Question39A' /> </div>
                                            <span>
                                                Which is missing?
                                            </span>
                                            <div> <img className="image-question" src={Question39_2} alt='Question39B' /> </div>
                                            <br />
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="A" {...register("Question39")} />
                                                    <span> A</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="B" {...register("Question39")} />
                                                    <span> B</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="C" {...register("Question39")} />
                                                    <span> C</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="D" {...register("Question39")} />
                                                    <span> D</span>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" value="E" {...register("Question39")} />
                                                    <span> E</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <hr className="solid" /><FormGroup>
                                            <Label>QUESTION 40</Label><br />
                                            <span>What number should replace the question mark?</span>
                                            <div> <img className="image-question" src={Question40} alt='Question40' /> </div>
                                            <br />
                                            <div>
                                                <input type="text"  {...register("Question40")} /><br />
                                            </div>
                                        </FormGroup>
                                        <br />
                                        <input type="submit" value="Submit" className='btn btn-success' id='SurveySubmit' disabled={loading? true : false}/>
                                        <br />
                                        <div className={loading ? "loader" : null} ></div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </>
            )}
        </div>

    );
}

export default Survey;
