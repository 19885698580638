import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";

import {ACCESS_TOKEN_NAME} from '../../config.js';
import {
    Navbar,
    NavItem,
    NavbarToggler,
    Collapse,
    NavLink,
    Nav,
    NavbarBrand
} from 'reactstrap';
import Logo from '../../assets/logo-black.png';
  
function App() {
  
    // Collapse isOpen State
    const [isOpen, setIsOpen] = React.useState(false);

    const navigate = useNavigate();

    var token = localStorage.getItem(ACCESS_TOKEN_NAME)
  
    return (
        <Navbar className='NavBar-Content' light expand="md">
                <NavbarBrand href="/"><img className='image-logo' src={Logo} alt='Logo Acesy'/></NavbarBrand>
                <NavbarToggler className='NavbarToggler'  onClick={() => { setIsOpen(!isOpen) }} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar> 
                        <NavItem>                         
                            {!token ? <NavLink href="/Login"><h4 className='link-Color'>Login</h4></NavLink> : null}
                        </NavItem>  
                        <NavItem>
                            {token ? <NavLink href="/Results"><h4 className='link-Color'>Results</h4></NavLink> : null}
                        </NavItem>
                        <NavItem>
                            {token ?  
                            <NavLink href="/Tokens"><h4 className='link-Color'>Tokens</h4></NavLink>: null}
                        </NavItem>
                        <NavItem>
                            {token ? <NavLink href="#" onClick={() => {localStorage.removeItem(ACCESS_TOKEN_NAME);navigate("/");} }><h4 className='link-Color'>LogOut</h4></NavLink> : null}                  
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
    );
}
  
export default App;