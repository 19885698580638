import React, { useState } from 'react';
import Survey from './Survey';
import axiosInstance from '../../Utilities/axios';
import { useForm } from "react-hook-form";

import {URL_BACKEND} from '../../config.js';

//import { questions } from '../datas/Listquestions';

import { Card, CardBody, Form,Label} from 'reactstrap';

//import { ArrowLeft } from 'react-bootstrap-icons';

import StartImage from '../../assets/starts.png';

function App() {

	const { register, handleSubmit, watch, formState: { errors } } = useForm();

	const [startTest, setstartTest] = useState(false);
	
	const [propsEmail, setPropsEmail] = useState('');
	const [propsToken, setPropsToken] = useState('');
	const [propsTimer, setPropsTimer] = useState(0);

	const [error, seterror] = useState('');

	const [loading, setLoading] = useState(false);

	//const [currentQuestion, setCurrentQuestion] = useState(0);
	//const [showScore, setShowScore] = useState(false);
	//const [score, setScore] = useState(0);

	const onSubmit = (data) => {

        console.log(data);

        saveData(data);

    };

    const saveData = async (data) => {
		setLoading(true)
		seterror('')
        axiosInstance.post(URL_BACKEND+"/testToken/accessTest", data)
            .then(res => {
				setLoading(false)
                console.log(res);
				if(res.status === 200){
					console.log(res);
					console.log(data)
					setPropsEmail(data.Email)
					setPropsToken(data.Token)
					setPropsTimer(res.data.time)
					setstartTest(true);
				}else if(res.status === 203){
					seterror(res.data.error);
				}else{
					seterror('error '+res.status);
				}

                
            })
			.catch(function (error){
				setLoading(false)
                seterror('Error encountered !')
                console.log(error)
            })
    }

	return (
		<div>
			{startTest ? (
				<Survey InfoEmail={propsEmail} InfoToken={propsToken} InfoTimer={propsTimer} />
			) :
				(
					<>
						<Card className='app'>
							<CardBody className='start-section'>
								<img src={StartImage} alt ='IQ Logo' />
								<div>
									<p>You are allowed to take the test only once after inserting your informations and clicking on start.</p>
									<p>The test lasts for 1.5 hours, so please make sure you are available during this time.</p>
									<p>Your token will only be active during 1.5 hours</p>
									<p>Make sure you have a stable internet connection.</p>
									<p className='text-danger'>{error}</p>
									<div className={loading ? "loader" : null} ></div>
								</div>
								<div className='mt-2'>
								<Form id='CandidateForm' onSubmit={handleSubmit(onSubmit)}>
										<Label >
                                            Token:
                                        </Label><br />
                                        <input type="text" {...register("Token", { required: true })} /><br />
                                        <Label>
                                            Email:
                                        </Label><br />
                                        <input type="email" {...register("Email", { required: true })} />
										<br /><br />
										<div className='Center-Content'>
											<input  type="submit" value="Start" className='btn btn-success' id='SurveySubmit' disabled={loading? true : false}/>
										</div>
								</Form>
								</div>
							</CardBody>
						</Card>


					</>
				)}
		</div>
	);
}

export default App
