import React from 'react';

import { URL_BACKEND } from '../../config.js';

import Table from '../../Utilities/table';

function FormTable() {
    const columns = [
        {
            name: 'FirstName',
            selector: row => row.FirstName,
            sortable: true,
            reorder: true,
        },
        {
            name: 'LastName',
            selector: row => row.LastName,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
            reorder: true,
        },
        {
            name: 'PhoneNumber',
            selector: row => row.PhoneNumber,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Score',
            selector: row => row.Score,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Time test start',
            selector: row => { const date = new Date(row.TimeTestSart); return date.toUTCString(); },
            sortable: true,
            reorder: true,
        },
        {
            name: 'Duration',
            selector: row => { const timer = new Date(row.CreatingDate) - new Date(row.TimeTestSart); 
                  const hours = Math.floor((timer % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                  const minutes = Math.floor((timer % (1000 * 60 * 60)) / (1000 * 60));      
                return hours +' h '+ minutes +' m '; },
            sortable: true,
            reorder: true,
        },
    ];

    return (
        <Table Columns={columns} URL={URL_BACKEND+"/testIq/allForm"} Title={"IQ TEST RESULTS"} />
    );
}

export default FormTable;