import React, { useState } from 'react';

import { Routes, Route} from 'react-router-dom'

import Header from './components/Header';
import Test from './components/Test/App';
import Error from './components/Error';
import Login from './components/Login';
import Results from './components/Results';
import Tokens from './components/Tokens';
import {ProtectedRoute} from './Utilities/ProtectedRoute'




function App() {
  const [title, updateTitle] = useState(null);
  const [errorMessage, updateErrorMessage] = useState(null);

    return (
      <Routes>
      <Route path="/" element={<div className='Home'><Test/></div>}/>
      <Route path="*" element={<Error/>}/>        
      <Route path="/Login" element={<Login showError={updateErrorMessage} updateTitle={updateTitle}/>}/>
      <Route path="/Results" element={
          <ProtectedRoute >
              <Results/>
            </ProtectedRoute>
      }/>  
      <Route path="/Tokens" element={
          <ProtectedRoute >
              <Tokens/>
            </ProtectedRoute>
      }/>  
    </Routes>);
  }

  export default App;