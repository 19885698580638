import {Row } from 'reactstrap';
import ErrorIllustration from '../../assets/404.png'



function Error() {
  return (
    <Row className='Error-Content'>
      <img className="image" src={ErrorIllustration} alt='Error-404' />
      <h2>
        Page Not Found
      </h2>
    </Row>
  )
}

export default Error
