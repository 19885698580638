import React, {useState} from 'react';
import axiosInstance from '../../Utilities/axios';
import {URL_BACKEND, ACCESS_TOKEN_NAME} from '../../config.js';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

function LoginForm(props) {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const [loading, setLoading] = useState(false);

    const [state , setState] = useState({
        successMessage: null,
        errorMessage: null
    })

    const navigate = useNavigate();

    
    const redirectToHome = () => {
        navigate("/");
    }

    const handleSubmitClick = async (data) => {
        setLoading(true)
        setState(prevState => ({
            ...prevState,
            'successMessage' :null,
            'errorMessage' : null
        }))
        axiosInstance.post(URL_BACKEND+'/auth/login', data)
            .then(function (response) {             
                setLoading(false)
                if(response.status === 200){
                    setState(prevState => ({
                        ...prevState,
                        'successMessage' : 'Login successful. You can access the restricted pages.',
                        'errorMessage' : null
                    }))
                    localStorage.setItem(ACCESS_TOKEN_NAME,response.data.token)

                    

                    console.log('token',localStorage.getItem(ACCESS_TOKEN_NAME));
                    redirectToHome()
                    props.showError(null)
                }
                else{
                    setState(prevState => ({
                        ...prevState,
                        'errorMessage' : 'Email and password do not match',
                        'successMessage' : null
                    }))
                    props.showError("Email and password do not match")
                }
            })
            .catch(function (error){
                setLoading(false)
                setState(prevState => ({
                    ...prevState,
                    'errorMessage' : 'Error encountered !',
                    'successMessage' : null
                }))
                console.log(error)
            })
    }
    
    return(
        <div className="card">
            <div className="card-header">
                Login
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(handleSubmitClick)}>
                    <div className="form-group text-left">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input type="email" 
                        className="form-control" 
                        id="email" 
                        aria-describedby="emailHelp" 
                        placeholder="Enter email" 
                        {...register("Email", { required: true })}
                    />
                    <small id="emailHelp" className="form-text text-muted">Log in with the IQ Test ACESY credentials.</small>
                    </div>
                    <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input type="password" 
                        className="form-control" 
                        id="password" 
                        placeholder="Password"
                        {...register("Password", { required: true })}
                    />
                    </div>              
                    <br /> 
                    <input 
                        type="submit" 
                        className="btn btn-primary"
                        value="Submit"
                        disabled={loading? true : false}
                    />
                </form>
            </div>    
            <div className={loading ? "loader" : null} ></div>       
            <div className={state.successMessage ? "alert alert-success mt-2" : "alert alert-danger mt-2"} style={{display: state.successMessage || state.errorMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage ? state.successMessage : state.errorMessage }
            </div>
        </div>
    )
}

export default LoginForm;