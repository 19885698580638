import React, { useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import axiosInstance from './axios';

import styled from 'styled-components';


function Table(props) {
    const [columns, setColumns] = useState([]);
    const [datas, setdatas] = useState([]);
    const [pending, setPending] = useState(true);
    const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([])

    const [state, setState] = useState({
        search: ""
    })
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
        setFilterText(e.target.value)
    }

    const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #7cc6fe;
        border-color: #7cc6fe;
    `;


    function Filtering() {
        setFilteredItems(datas.filter(
            item => (item.Email &&  item.Email.toLowerCase().includes(filterText.toLowerCase()))
            || (item.FirstName && item.FirstName.toLowerCase().includes(filterText.toLowerCase()))
            || (item.LastName && item.LastName.toLowerCase().includes(filterText.toLowerCase()))
            || (item.PhoneNumber && item.PhoneNumber.toLowerCase().includes(filterText.toLowerCase()))
            || (item.Token && item.Token.toLowerCase().includes(filterText.toLowerCase()))
        ))

    }


    useEffect(() => {
        const timeout = setTimeout(() => {
            setPending(true);
            axiosInstance.get(props.URL)
                .then(res => {
                    console.log(res);

                    setdatas(res.data);
                    setFilteredItems(res.data);

                    setColumns(props.Columns);

                    setPending(false);
                }).catch(err => {
                    setPending(false);
                    console.log(err)
                })

            
        }, 1000);
        return () => clearTimeout(timeout);
    }, [props.ReloadTable]);

    return (
        <Row className='Table-Content'>
            <Col>
                <Card>
                    <CardBody >
                        <Row className='search-component'>
                            <input type="search"
                                className=" form-control input-search"
                                id="search"
                                aria-describedby="searchHelp"
                                placeholder="search"
                                value={state.search}
                                onChange={handleChange}
                            />
                            <ClearButton onClick={Filtering} >🔍</ClearButton>
                        </Row>
                        <DataTable
                            title={props.Title}
                            columns={columns}
                            data={filteredItems}
                            progressPending={pending}
                            expandableRows={true}
                            expandableRowsComponent={ExpandedComponent}
                            pagination
                            responsive
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default Table;