import {  Navigate } from "react-router-dom";
import {ACCESS_TOKEN_NAME} from '../config.js';

export const ProtectedRoute = ({ Redirect, children }) => {
  var token = localStorage.getItem(ACCESS_TOKEN_NAME)
  console.log('token expect',token);
  if (!token) {
    return <Navigate to="/Login" replace />;
  } 
    return children;
};