import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import {useCountdown} from './useCountdown';

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <div
        className="countdown-link"
      >
        <DateTimeDisplay value={hours} type={'Hour(s)'} isDanger={hours <= 0} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={'Min(s)'} isDanger={minutes <= 10} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Second(s)'} isDanger={minutes <= 10} />
      </div>
    </div>
  );
};


const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
      return <div className="expired-notice">
      <span id="EndTime">Time Elapsed !!!</span>
    </div>;
    } else {
      return (
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      );
  }
};

export default CountdownTimer;