import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';

import './styles/index.css';

import { BrowserRouter as Router} from 'react-router-dom'

import Header from './components/Header';



ReactDOM.render(
  <Router>
        <Header/>    
        <div className='Home'><App/></div>
  </Router>,
  document.getElementById('root')
);
