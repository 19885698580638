import React, {useState} from 'react';
import axiosInstance from '../../Utilities/axios';
import {URL_BACKEND,} from '../../config.js';
import { useForm } from "react-hook-form";

import Table from '../../Utilities/table';

function TokensForm() {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const [state , setState] = useState({
        successMessage: null,
        errorMessage: null
    })

    const [reloadTable, setReloadTable] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleSubmitClick = async (data) => {
        setLoading(true)
        setState(prevState => ({
            ...prevState,
            'successMessage' : null,
            'errorMessage' : null
        }))
        axiosInstance.post(URL_BACKEND+'/testToken/createToken', data)
            .then(function (response) {
                setLoading(false)
                if(response.status === 201){
                    setState(prevState => ({
                        ...prevState,
                        'successMessage' : response.data.message + ' Token: ' + response.data.tokenTest.Token,
                        'errorMessage' : null
                    }))
                    setReloadTable(true)
                }else if(response.status === 203){
                    setState(prevState => ({
                        ...prevState,
                        'errorMessage' : response.data.error,
                        'successMessage' : null
                    }))
                }
                else{
                    setState(prevState => ({
                        ...prevState,
                        'errorMessage' : 'Error '+response.status,
                        'successMessage' : null
                    }))
                }
                setReloadTable(false)
            })
            .catch(function (error){
                setLoading(false)
                setState(prevState => ({
                    ...prevState,
                    'errorMessage' : 'Error encountered !',
                    'successMessage' : null
                }))
                console.log(error)
                setReloadTable(false)
            })
    }

    const columns = [
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Token',
            selector: row => row.Token,
            sortable: true,
            reorder: true,
        },    
        {
            name: 'Used',
            selector: row => {if(row.Used){return('True')}else{ return('False')} },
            sortable: true,
            reorder: true,
        },             
        {
            name: 'UpdatingDate',
            selector: row => {const date =  new Date(row.UpdatingDate); return date.toUTCString();},
            sortable: true,
            reorder: true,
        },
        {
            name: 'CreatingDate',
            selector: row => {const date =  new Date(row.CreatingDate); return date.toUTCString();},
            sortable: true,
            reorder: true,
        },
    ];
    
    return(
        <div>
            <div className="card">
                <div className="card-header">
                    Create token for Test
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit(handleSubmitClick)}>
                        <div className="form-group text-left">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input type="email" 
                            className="form-control" 
                            id="email" 
                            aria-describedby="emailHelp" 
                            placeholder="Enter email" 
                            {...register("Email", { required: true })}
                        />
                        </div>
                        <br />
                        <button 
                            type="submit" 
                            className="btn btn-primary"
                            disabled={loading? true : false}
                        >Submit</button>
                    </form>
                </div>                   
                <div className={loading ? "loader" : null} ></div>        
                <div className={state.successMessage ? "alert alert-success mt-2" : "alert alert-danger mt-2"} style={{display: state.successMessage || state.errorMessage ? 'block' : 'none' }} role="alert">
                    {state.successMessage ? state.successMessage : state.errorMessage }
                </div>
            </div>
            <br/><br/>
            <div>
                <Table Columns={columns} URL={URL_BACKEND+"/testToken/getAllTokens"} Title={"IQ TEST TOKENS"} ReloadTable={reloadTable} />
            </div>
        </div>
        
    )
}

export default TokensForm;